<template>
  <div id="sidebar">
    <div id="sidebar-container" class="text-left frosted-glass" :class="{'mobile': mobileFlag, 'desktop': mobileFlag == false}">
      <div class="container p-4">
        <div class="row" v-if="mobileFlag === false">
          <div class="col-12 d-inline-flex">
            <h1>NYC Pedestrian Density</h1><small style="color:lightgrey;">beta</small>
          </div>
        </div>
        <div class="row mt-2">
          <div class="col-12">
            <div v-if="mobileFlag === true" class="d-flex">
              <h1 >NYC Pedestrian Density</h1><small style="color:lightgrey;">beta</small>
            </div>
            <p class="description-text text-align-justify">
              Exploratory data visualization of potential pedestrian volumes for sidewalk activities in New York City. Sidewalk activity is a combination of pedestrians walking and queuing for businesses such as retail, restaurants and grocery stores. For more detail, see <span class="anchor-highlight" @click="aboutClick" >About</span>
            </p>
            <p class="description-text text-align-justify">
              Click on sidewalks to get more details on sidewalk population estimates and zoom in to see street level business detail. In the coming weeks this tool will be updated to include more neighborhoods. Visit our companion app to see our <a href="https://sidewalk.kpfui.dev" target="_blank">city-wide NYC Sidewalk Analysis tool.</a>
            </p>
          </div>
        </div>
        <div class="row mt-2">
          <div class="col-12">
            <h4>Select a neighborhood</h4>
          </div>
        </div>
        <div class="row mt-2">
          <b-form-select v-model="neighborhoodSelected" :options="neighborhoodOptions" size="sm" class="col-10 mx-auto"></b-form-select>
        </div>
        <hr>
        <div class="row mt-2">
          <div class="col-12 mt-2">
            <h4>Select Time of Day</h4>
          </div>
        </div>
        <div class="row mt-4 mb-4">
          <div class="col-10 col-lg-10 col-sm-10 m-0 p-1 mx-auto">
            <div class="slider-parent">
              <vue-slider v-if="Object.keys(sliderObject).length > 1" :title="'adjust ' + sliderObject.label" :ref="sliderObject.dataName" @drag-end="setInputValues" @error="sliderError" :stop-propagation="true" v-bind="sliderOptions" :clickable="false" :absorb="true" :lazy="true" :marks="true" :data="sliderObject.data" v-model="sliderObject.value">
              </vue-slider>
            </div>
          </div>
        </div>
        <div class="row mt-2"></div>
        <div class="row mt-2">
          <div class="col-12 mt-2">
            <h4>Pedestrian Traffic</h4>
            <small>square feet of sidewalk per person</small>
          </div>
        </div>
        <div class="row m-2 metric-gradient-parent">
          <div class="metric-gradient col-12 col-md-12 col-sm-12" ref="metric-gradient" :style="{'background': 'linear-gradient(to right,' + legendGradient.toString() +')'}"></div>
        </div>
        <div class="row">
          <div class="col-12 d-flex justify-content-between">
            <h5>{{colorExtent.max}} <span style="font-size: 1rem;">sqft</span></h5>
            <h5>{{colorExtent.min}} <span style="font-size: 1rem;">sqft</span></h5>
          </div>
        </div>
        <!-- <hr> -->
        <div class="row mt-2">
          <div class="col-12">
            <h4>Business Colors</h4>
          </div>
        </div>
        <div class="row mt-2">
          <div class="col-12 d-flex flex-wrap justify-content-between">
            <div class="d-flex" v-for="(store, index) in Object.keys(stores)" :key="index">
              <div class="color-bubble" :style="{'background-color': stores[store]}"></div><div>{{store}} </div>
            </div>
          </div>
        </div>
        <!-- <hr> -->
        <div class="row mt-2">
          <div class="col-12">
            <h4>NYC Open Street Locations</h4>
            <p class="description-text text-align-justify">
              New York City is opening streets daily for pedestrians and cyclists to use the roadbed of the street for social distancing during the COVID-19 crisis.
            </p>
          </div>
        </div>
        <div class="row mt-2">
          <div class="col-12 d-flex align-items-center">
            <div class="open-street-line"></div>
            <div class="ml-4">
              Open Street
            </div>
          </div>
        </div>
        <hr>
        <div class="row mt-4 d-flex justify-content-center">
          <b-btn class="block col-4" @click="aboutClick">Open About</b-btn>
        </div>
        <hr>
        <div class="row">
          <div class="col-12">
            <p class="description-text"> 
              This is not a public health analysis nor a safety recommendation. It is an aggregation and visualization of third party data sets that may contribute to sidewalk pedestrian density and is provided “as is.”
            </p>
          </div>
        </div>
        <hr>
        <div class="row">
          <div class="col-12 text-center">
            <div>
              built by<br /> <a href="https://ui.kpf.com" target="_blank"><b-img :src="kpfui_logo" class="icon-img" /></a>
            </div>
            <small class="m-2">© 2020 Kohn Pedersen Fox Associates </small>
          </div>
        </div>
      </div>
    </div>
    <!-- <div v-if="mobileFlag" class="mobile-bar d-flex justify-content-around align-items-center">
      <div class="mobile-toggles">
        <div class="d-flex justify-content-center align-items-center">
          <mobileIcons :iconType="'tool'" :active="sidebarOpen" />
        </div>
        <div class="subtitle">{{toggle.name}}</div>
      </div>
    </div> -->
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

// vue slider import
import VueSlider from 'vue-slider-component'
// slider template styling
import 'vue-slider-component/theme/default.css'

export default {
  name:'sidebar',
  components:{
    VueSlider
  },
  props:{
    sliderTime: String,
    sliderObject: Object
  },
  data(){
    return {
      sidebarOpen: true,
      kpfui_logo: require("@/assets/kpfui_logo.png"),
      stores: {
        'Rest/Bar': '#F04544',
        'Supermarket': '#C5FF4E',
        'Conv/Pharm': '#519FFF',
        'Bank': '#C559FF'
      },
      neighborhood: null,
      neighborhoods:[
        { value: [-73.88311256683355,40.74882843605454], text: 'Jackson Heights' },
        { value: [-73.944520,40.684880], text: 'Bed Stuy' },
        { value: [-73.938110, 40.847570], text: "Washington Heights"},
        { value: [ -74.010124, 40.652390 ], text: "Sunset Park"},
        { value: [  -73.981647, 40.759885 ], text: "Midtown Manhattan"},
        { value: [ -73.939619, 40.820428 ], text: "Central Harlem"},
        { value: [-73.903540, 40.882030], text: "Kingsbridge"}
      ],
      sliderOptions:{
        "dotSize": 14,
        "lazy": true,
        "tooltip": "none",
        "railStyle":{
          "background-color": "rgba(253, 203, 204, 0.41)"
        },
        "dotStyle":{
          "background-color": "#F24B6A"
        },
        "processStyle":{
          "background-color": "#ff4c5f"
        }
      }
    }
  },
  computed:{
    ...mapGetters({
      'mobileFlag': "getMobileFlag",
      "options": "getOptions",
      "colorExtent": "getColorExtent",
      "colorScale": "getColorScale"
    }),
    neighborhoodSelected:{
      get(){
        return this.neighborhood
      },
      set(newValue){
        this.$emit('flyTo', newValue, 14)
      }
    },
    neighborhoodOptions(){
      const defaultOption = { value: null, text: 'select a neighborhood to zoom to.' , disabled: true }
      const n =this.neighborhoods

      const options = n.sort((a, b) => {
        const nameA = a.text.toUpperCase();
        const nameB = b.text.toUpperCase();
        if (nameA < nameB)  return -1;
        if (nameA > nameB)  return 1;
        return 0;
      });

      options.unshift(defaultOption)

      return options
    },
    legendGradient(){

      let colorRamp = []
      
      for (let i = this.colorExtent.max; i > this.colorExtent.min; i-=0.1) {
        colorRamp.push(this.colorScale['area_p_12'](i))
      }

      return colorRamp
    }
  },
  methods:{
    aboutClick(){
      this.$store.commit("setAboutToggle", true)
    },
    setInputValues(){
      this.$emit('sliderTime', this.options[this.sliderObject.value])
    },
    sliderError(error){
      console.error(error)
    }
  }
}
</script>

<style lang="scss">
.anchor-highlight{
  color: #007bff;
  text-decoration: none;
  background-color: transparent;

  &:hover{
    text-decoration: underline;
    cursor: pointer;
  }
}
.open-street-line{
  height: 5px;
  width: 75px;

  background: #3ad3ad;
}
ul{
  list-style: none;
}
.color-bubble{
  height: 15px;
  width: 15px;

  border-radius: 50%;

  display: inline;

  margin-right: 5px;
}
.mobile-bar{
  position: absolute;
  bottom: 0px;
  width: 100%;
  background: $black;

  padding: 1rem;

  z-index: 10;

  border-top: 1px solid $dark-grey;

  pointer-events: none;
}
// SIDEBAR 
// SIDEBAR 
#sidebar-container{
  position: absolute;
  left: 0;

  // background: $black;
  background: transparent;
  overflow-y: auto;

  &.mobile{
    bottom: 80px;
    width: 100%;
    height: calc(50vh - 80px);
  }
  &.desktop{
    top: 0px;
    width: 525px;
    height: 100vh;
  }
}
::-webkit-scrollbar {
  width: 6px;
} 
::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3); 
} 
::-webkit-scrollbar-thumb {
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3); 
}
// SLIDER OPTIONS
.vue-slider-mark{
  background-color:#fdcbcc;
  border-radius: 50%;
  font-family: 'Roboto Mono', monospace;
  opacity:0.5;
  line-height: 1.33;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: 0.45px;
}
.vue-slider-mark-label{
  font-family: 'Roboto Mono', monospace;
}

.metric-gradient-parent{
  padding: 0 15px;
}
.metric-gradient{
  border-radius: 5px;
  height: 15px;
}
</style>