import Vue from 'vue';
import Vuex from 'vuex';
import { json } from 'd3-fetch';
import { interpolateHcl } from "d3-interpolate"
import { scaleLinear} from 'd3-scale';
import axios from 'axios';

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    streetData:[],
    sliderObject:{},
    mobileFlag: false,
    colorScale: [],
    options: [],
    colorExtent: {},
    businessPointData: [],
    aboutToggle: false
  },
  getters :{
    getAboutToggle(state){
      return state.aboutToggle
    },
    getStreetData(state){
      return state.streetData
    },
    getMobileFlag(state){
      return state.mobileFlag
    },
    getSliderObject(state){
      return state.sliderObject
    },
    getColorScale(state){
      return state.colorScale
    },
    getOptions(state){
      return state.options
    },
    getColorExtent(state){
      return state.colorExtent
    },
    getBusinessPointData(state){
      return state.businessPointData
    }
  },
  mutations: {
    setStreetData(state, newStreetData){
      state.streetData = newStreetData
    },
    setMobileFlag(state, newMobileFlag){
      state.mobileFlag = newMobileFlag
    },
    setSliderObject(state, newSliderObject){
      state.sliderObject = newSliderObject
    },
    setColorScale(state, newColorScale){
      state.colorScale = newColorScale
    },
    setOptions(state, newOptions){
      state.options = newOptions
    },
    setColorExtent(state, newColorExtent){
      state.colorExtent = newColorExtent
    },
    setBusinessPointData(state, newBusinessPointData){
      state.businessPointData = newBusinessPointData
    },
    setAboutToggle(state, newAboutToggle){
      state.aboutToggle = newAboutToggle
    }
  },
  actions: {
    readBusinessData(context){
      json('./urbano_business_data_071420.json', ()=>{}).then((response)=>{
        context.commit('setBusinessPointData', response)
      })
    },
    readStreetAddressFromLatLng(context, payload){
      const lat = payload.lngLat.lat,
        lng = payload.lngLat.lng;

    
      return new Promise((resolve, reject) => {
        // Do something here... lets say, a http call using vue-resource
        axios.get("https://api.mapbox.com/geocoding/v5/mapbox.places/"+lng+","+lat+".json?types=address&access_token=pk.eyJ1Ijoia3BmdWkiLCJhIjoiY2p6MWcxMXl4MDFlbTNsbDc1bnp6N3FjYSJ9.66qFOXwI661MOPOf7x96yA").then((response)=>{
            // http success, call the mutator and change something in state
            resolve(response.data.features[0].text);  // Let the calling function know that http is done. You may send some data back
        }, error => {
            // http failed, let the calling function know that action did not work out
            reject(error);
        })
      })
    },
    getNYCOpenStreetData(){
      return new Promise((resolve, reject) => {
        // Do something here... lets say, a http call using vue-resource
        axios.get("https://data.cityofnewyork.us/resource/uiay-nctu.json").then((response)=>{
            // http success, call the mutator and change something in state
            const geojsonStructure = {
              "type": "FeatureCollection",
              "features": []
            }
            geojsonStructure.features = response.data.map((d)=>{
              if(d['type'] !== "Protected Bike Lane"){
                const geometry = Object.assign({}, d['the_geom']);
                  delete d["the_geom"];
                  return {
                    'type': "Feature",
                    'properties': d,
                    "geometry": geometry
                  }
              }else{
                return {}
              }
              
            });
            resolve(geojsonStructure);  // Let the calling function know that http is done. You may send some data back
        }, error => {
            // http failed, let the calling function know that action did not work out
            reject(error);
        })
      })
    },
    readStreetData(context){
      json('./urbano_data_v4.json', ()=>{}).then((response)=>{

        const options = {
          "9:00 am": "area_p_9", 
          "12:00 pm": "area_p_12",
          "7:00 pm" :"area_p_19"
        }

        const default_time = "area_p_12"

        const sliderObject = {
          dataName: 'time_options',
          label: 'time',
          data: Object.keys(options),
          value: Object.keys(options)[1]
        }

        let colorDomain = {}
        const keys = Object.values(options)

        keys.forEach((d)=>{ colorDomain[d] = [] })

        response.features.forEach((d)=>{
          keys.forEach((j)=>{
            colorDomain[j].push(d.properties[j]);
          })
        })
        // meters squared area
        let min = 30,
          max = 150;
        // sorts and gets min/max from each domain
        // return a color scale per time interval

        keys.forEach((d)=>{
          // colorDomain[d] = colorDomain[d].sort((a,b) => a -b);

          // colorDomain[d] = scaleSequential(interpolateInferno).domain([max,min]).unknown("#000000")
          // colorDomain[d] = scaleSequential((t)=>{    
          //   return interpolateInferno(t/5) })
          //   .domain([max,min]);
          colorDomain[d] = scaleLinear().domain([max, min])
          // .range(["#43016A","#FEB300"])
          .range(["#2C0145", "#FEDA00"])
          .interpolate(interpolateHcl)
          .unknown("#000000")
          .clamp(true)
        })

        response.features.forEach((d)=>{
          d['properties']['color'] = colorDomain[default_time](d.properties[default_time])
        });

        context.commit('setOptions', options)
        context.commit('setColorScale', colorDomain);
        context.commit('setSliderObject', sliderObject);
        context.commit('setStreetData', response);
        context.commit('setColorExtent', {min:min, max:max})
      })
    }
  }
})
