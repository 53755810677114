<template>
  <div id="about-section" class="container">
    <div class="row mt-4">
      <div class="col-10 mx-auto">
        <div class="row mt-4">
          <div class="col-12">
            <h2>Disclaimer</h2>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <p>
              This is not a public health analysis nor a safety recommendation. It is an aggregation and visualization of third party data sets that may contribute to sidewalk pedestrian density and is provided “as is.”
            </p>
          </div>
        </div>
        <hr>
        <div class="row">
          <div class="col-12">
            <h4>Data Sources</h4>
            <ul>
              <li v-for="(source,index) in dataSourcesAlpha" :key="index">
                <a :href="source.link" target="_blank">{{source.name}}</a>
              </li>
            </ul>
          </div>
        </div>
        <hr />
        <div class="row">
          <div class="col-12">
            <h3>Methodology</h3>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <p>
              Exploratory data visualization of potential pedestrian volumes for sidewalk activities in Jackson Heights Queens. Sidewalk activity is a combination of pedestrians walking and queuing for businesses such as retail, restaurants and grocery stores. Zoom in to see street level business detail. In the coming weeks this tool will be updated to include more neighborhoods.
            </p>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <h4>
              Neighborhood Population
            </h4>
            <p>
              Resident population of the neighborhood is derived from the US Census (2010) population per census block, distributed by the amount of “Residential Area” per tax lot to achieve the proportional population of each residential unit
            </p>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <h4>
              Amenity Demands
            </h4>
            <p>
              The simulated pedestrian volume is this study is based on Amenity Demand Profiles from <a href="http://urbano.io/" target="_blank">Urbano</a>, a Grasshopper plug-in developed by the Cornell Environmental Systems Lab (ES Lab). Amenity Demand information is aggregated volume indicators of businesses based on “Popular Times” from Google Maps. The final values represent the percentage of neighborhood population expected to visit each amenity category at certain hours of the day (T). See table below:
            </p>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <assumptionTable />
          </div>
        </div>
        <div class="row mt-4">
          <div class="col-12">
            <h4>
              Destination Choice Factor
            </h4>
            <p>
              A pedestrian’s destination choice is based on two factors: 1.) Amenity Capacity and 2.) Walking Distance. A simulated pedestrian trip to any amenity category will choose from all applicable businesses of the category within a 10 minute walking distance, and prioritize businesses not yet at capacity for a shorter wait. This helps to create more realistic pedestrian behaviors in deciding which business to patron.
            </p>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <h4>
              Queuing Pedestrian
            </h4>
            <p>
              The queuing population is assumed to be around 10% of the total number of patrons to a business. The total number is based on the simulated resident trip visits of each hour. Queuing pedestrians are assumed to form a single-file, 6 feet apart, line along the business perimeters on the sidewalk. 
            </p>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <h4>
              Walking Pedestrian
            </h4>
            <p>
              The walking pedestrian number is derived from the total street travel count produced by the Urbano Trip simulation. It is assumed that 2% of the total number of pedestrians of each street would be walking and counted at a specific snapshot in time. 
            </p>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <h4>
              Usable Sidewalk Area
            </h4>
            <p>
              Based on the advice from the DCP & DOJ, 2-3 feet of the sidewalk is expected to be occupied by common street elements such as tree pits, fire hydrants, and trash. The study assumes that 20% of the sidewalk area is occupied and thus 80% of the sidewalk area is used to calculate the available sidewalk area per pedestrian.
            </p>
          </div>
        </div>
        <hr>
        <div class="row">
          <div class="col-12 text-left">
            <a href="https://ui.kpf.com" target="_blank"><b-img :src="kpfui_logo" /></a>
            <div style="margin-bottom: 1rem;"></div>
            <p>
              This tool is developed by <a href="https://ui.kpf.com">KPF Urban Interface</a>, a research and design wing within <a href="https://www.kpf.com">Kohn Pedersen Fox</a> Architects, that engages with the contemporary issues that impact the future of our buildings and cities. KPFui uses spatial and temporal urban data analytics for informed decision making in the design of buildings and cities for people while engaging with some of the most critical contemporary urban topics: livability, sustainability, and mobility.
            </p>
            <p>
              We believe that smart cities & buildings are not about drones, sensors or autonomous vehicles. A truly smart city should leverage data to design better neighborhoods and utilize technology to make that data transparent and publicly accessible. We think people should be in control of technology and not the other way around.
            </p>
          </div>
        </div>
        <div class="row">
          <div class="col-12 text-left">
            <a href="https://es.aap.cornell.edu/"><b-img :src="cornell_logo"></b-img></a>
            <div style="margin-bottom: 1rem;"></div>
            <p>
              <a href="http://urbano.io/">Urbano.io</a> is a tool promote mobility aware design through pedestrian simulation and analysis developed by the Environmental Systems Lab at Cornell University. The mission of the lab is to enhance the knowledge of sustainable architecture and urbanism through innovative educational programming and strategic research at the intersection of architectural design, engineering, computer science, and building performance simulation
            </p>
            <p class="font-italic">
              Timur Dogan, Yang Yang, Samitha Samaranayake & Nikhil Saraf (2020) <a href="https://doi.org/10.1080/24751448.2020.1705716" target="_blank">Urbano: A Tool to Promote Active Mobility Modeling and Amenity Analysis in Urban Design, Technology|Architecture + Design</a>, 4:1, 92-105, DOI: 10.1080/24751448.2020.1705716
            </p>
          </div>
        </div>
        <div class="row">
          <div class="col-12 text-left">
            <a href="https://livexyz.com/" target="_blank"><b-img :src="xyz_logo" style="height: 40px;" /> </a>
            <div style="margin-bottom: 1rem;"></div>
            <p>
              <a href="https://livexyz.com/" target="_blank">Live XYZ</a> is a New York City based startup driven by a mission to put every storefront on the map. We deliver precise, complete storefront and point-of-interest data and technology. Using our proprietary surveying and mapping methodology, Live XYZ has built a hyper-accurate map of places and how they change over time. Since 2014, our on-the-ground mapping team has been walking the streets of New York, mapping, photographing and classifying every point-of-interest on every block with the Live XYZ mobile app. The result is a ground-truthed and cloud-verified first-party dataset of storefront information, trusted by city governments, community organizations, and real estate professionals.
            </p>
          </div>
        </div>
        <div class="row">
          <div class="col-12 text-center">
            <div>
              built by<br /> <a href="https://ui.kpf.com" target="_blank"><b-img :src="kpfui_logo" /></a>
            </div>
            <small class="m-2">© 2020 Kohn Pedersen Fox Associates</small>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

import assumptionTable from "@/components/assumption_table.vue";

export default {
  name: "about-section",
  components:{
    assumptionTable
  },
  data(){
    return {
      kpfui_logo: require("@/assets/kpfui_logo.png"),
      xyz_logo: require("@/assets/xyz_logo.png"),
      cornell_logo: require("@/assets/cornell_logo.png"),
      dataSources: [
        {name: "Urbano.io - Pedestrian Volumns and Amenity Demand Modeling", link:" http://urbano.io/"},
        {name:"Business Location Data - LiveXYZ access June 2020", link:"https://livexyz.com/" },
        {name:"Land Use Tax Lots - NYC Pluto", link:"https://www1.nyc.gov/site/planning/data-maps/open-data/dwn-pluto-mappluto.page"},
        {name:"Building Footprints - NYC Open Data", link: "https://data.cityofnewyork.us/Housing-Development/Building-Footprints/nqwf-w8eh"},
        {name:"Street Centerline - NYC Open Data", link: "https://data.cityofnewyork.us/City-Government/NYC-Street-Centerline-CSCL-/exjm-f27b"},
        {name:"Census Blocks - US Census (2010)", link: "https://data.cityofnewyork.us/City-Government/2010-Census-Blocks/v2h8-6mxf"},
        {name:"Resident Population", link: "https://www.census.gov/"},
        {name:"Sidewalk - NYC Open Data", link: "https://data.cityofnewyork.us/City-Government/Sidewalk/vfx9-tbb6"},
        {name: "NYC Open Streets - NYC Open Data", link: "https://data.cityofnewyork.us/Health/Open-Streets-Locations/uiay-nctu"}
      ]
    }
  },
  computed:{
    ...mapGetters({
      'mobileFlag': "getMobileFlag"
    }),
    dataSourcesAlpha(){
      let d = this.dataSources
      return d.sort((a,b)=>{
        const ta = a.name.toUpperCase(),
          tb = b.name.toUpperCase();

        return (ta < tb) ? -1 : (ta > tb) ? 1 : 0; 
      })
    }
  }
}
</script>

<style lang="scss" scoped>
#about-section{
  p{
    text-align: justify;
  }
}
img{
  height: 25px;
  width: auto;
}
</style>