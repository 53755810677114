<template>
  <div class="Map">
    <div id="map-container">
      <MglMap
        :accessToken="accessToken"
        :mapStyle.sync="mapStyle"
        container="map-parent"
        @load="onMapLoaded"
        :zoom="zoom"
        :center="center"
      >
        <MglNavigationControl position="top-right" />
        <MglGeolocateControl position="top-right" />
      </MglMap>
    </div>
    <div v-if="mobileFlag" class="mobile-bar d-flex justify-content-around align-items-center">
      <div @click="changeToggle(index)" class="mobile-toggles" v-for="(toggle, index) in mobileToggles" :key="index">
        <div class="d-flex justify-content-center align-items-center">
          <mobileIcons :iconType="toggle.iconType" :active="toggle.active" />
        </div>
        <div class="subtitle">{{toggle.name}}</div>
      </div>
    </div>
    <transition name="slide">
      <div v-if="mobileToggles[0].active === true" >
        <sidebar @sliderTime="updateMap" :sliderObject="sliderObject" :aboutToggle="aboutToggle" @flyTo="flyTo"/>
      </div>
    </transition>
    <transition name="slide">
      <div id="about-container" class="frosted-glass mobile" v-if="mobileFlag === true && aboutToggle === true">
        <aboutSection />
      </div>
    </transition>
    <transition name="slide">
      <div id="about-container" class="frosted-glass desktop" v-if="mobileFlag === false && aboutToggle === true">
        <b-btn class="about-close" @click="aboutClick">X</b-btn>
        <aboutSection />
      </div>
    </transition>
    <loading :loadingFlag="loadingFlag" />
  </div>
</template>

<script>
// IMPORT MAPBOX FUNCTIONS AND TEMPLATES
import Mapbox from 'mapbox-gl'

import {
  MglMap,
  MglNavigationControl,
  MglGeolocateControl
} from 'vue-mapbox'

// mapbox containers
var map = null
var popup = null
var featureID = null

import { mapGetters } from 'vuex'

import sidebar from "@/components/sidebar.vue"

import aboutSection from "@/components/about_section.vue";
import mobileIcons from "@/components/mobile_icons.vue";
import loading from "@/components/loading.vue";

export default {
  name: 'Map',
  components: {
    MglMap,
    MglNavigationControl,
    MglGeolocateControl,
    sidebar,
    mobileIcons,
    aboutSection,
    loading
  },
  data(){
    return {
      accessToken:
        'pk.eyJ1Ijoia3BmdWkiLCJhIjoiY2p6MWcxMXl4MDFlbTNsbDc1bnp6N3FjYSJ9.66qFOXwI661MOPOf7x96yA',
      mapStyle: 'mapbox://styles/mapbox/dark-v10',
      zoom: 13,
      center: [-73.939014, 40.843967],
      loadingFlag: true,
      mobileToggles:[
        {
          name: 'explore',
          iconType: 'tool',
          active: true
        },
        {
          name: 'about',
          iconType: "info",
          active: false
        }
      ]
    }
  },
  created(){
    this.mapbox = Mapbox
  },
  mounted(){
    this.subscribeToStore()
  },
  destroyed(){
    this.unsubscribeFromStore()
  },
  computed:{
    ...mapGetters({
      "streetData": "getStreetData",
      "colorScale": "getColorScale",
      "sliderObject": "getSliderObject",
      "options": "getOptions",
      "businessPointData": "getBusinessPointData",
      "mobileFlag": "getMobileFlag",
      "aboutToggle": "getAboutToggle"
    }),
    getSliderTimeSplit(){
      return this.options[this.sliderObject.value].split("p_")[1]
    }
  },
  methods:{
    changeToggle(index){

      let activeToggle = this.mobileToggles[index]

      if(activeToggle.active){
        activeToggle.active = !activeToggle.active 
      }else{
        this.mobileToggles.forEach((d)=> d.active = false)
        this.mobileToggles[index].active = true
      }

      if(index === 1){
        this.$store.commit('setAboutToggle', this.mobileToggles[index].active)
      }
    },
    aboutClick(){
      this.$store.commit('setAboutToggle', false)
    },
    flyTo (coords, zoom) {
      map.flyTo({
        center: [coords[0], coords[1]],
        zoom: zoom,
        speed: 1,
        curve: 1,
        easing (t) {
          return t
        }
      })
    },
    subscribeToStore(){
      this.streetDataUnsubscriber = this.$store.subscribe((mutation) => {
        switch (mutation.type) {
          case 'setStreetData':
            this.addStreetDataToMap(this.streetData)
            break
        }
      })
      this.businessDataUnsubscriber = this.$store.subscribe((mutation) => {
        switch (mutation.type) {
          case 'setBusinessPointData':
            this.addBusinessPointDataToMap(this.businessPointData)
            
            break
        }
      })
    },
    unsubscribeFromStore(){
      this.streetDataUnsubscriber();
    },
    addOpenStreetsData(){
      
      this.$store.dispatch('getNYCOpenStreetData', []).then(response => {

        let layers = map.getStyle().layers;
 
        let labelLayerId;
        for (let i = 0; i < layers.length; i++) {
          if (layers[i].type === 'symbol' && layers[i].layout['text-field']) {
            labelLayerId = layers[i].id;
            break;
          }
        }

        map.addSource('open-streets-source-data', {
          type: 'geojson',
          data: response
        })

        map.addLayer({
          'id': 'open-streets-lines',
          'minzoom': 12,
          'type': 'line',
          'source': 'open-streets-source-data',
          'paint': {
            'line-width': 3,
            'line-color': '#3ad3ad'
          }
        }, labelLayerId);
      })
    },
    createBusinessDataSource(){
      
      map.addSource('business-source-data', {
        type: 'geojson',
        data: {
          'type': 'FeatureCollection',
          'features': [{
            'type': 'Feature',
            'properties': { 'name': 'Null Island' },
            'geometry': {
              'type': 'Point',
              'coordinates': [ 0, 0 ]
            }
          }]
        }
      })
    },
    createStreetDataSource(){
      map.addSource('street-source-data', {
        type: 'geojson',
        data: {
          'type': 'FeatureCollection',
          'features': [{
            'type': 'Feature',
            'properties': { 'name': 'Null Island' },
            'geometry': {
              'type': 'Point',
              'coordinates': [ 0, 0 ]
            }
          }]
        },
        generateId: true
      })
    },
    add3dBuildings(){
      var layers = map.getStyle().layers;
 
      var labelLayerId;
      for (var i = 0; i < layers.length; i++) {
        if (layers[i].type === 'symbol' && layers[i].layout['text-field']) {
          labelLayerId = layers[i].id;
          break;
        }
      }

      map.addLayer({
        'id': '3d-buildings',
        'source': 'composite',
        'source-layer': 'building',
        'filter': ['==', 'extrude', 'true'],
        'type': 'fill-extrusion',
        'minzoom': 17,
        'paint': {
          'fill-extrusion-color': '#aaa',
          // use an 'interpolate' expression to add a smooth transition effect to the
          // buildings as the user zooms in
          'fill-extrusion-height': [
            'interpolate',
            ['linear'],
            ['zoom'],
            15,
            0,
            15.05,
            ['get', 'height']
          ],
          'fill-extrusion-base': [
            'interpolate',
            ['linear'],
            ['zoom'],
            15,
            0,
            15.05,
            ['get', 'min_height']
          ],
          'fill-extrusion-opacity': 1
        }
      },labelLayerId);
    },
    addBusinessPointDataToMap(businessPointData){
      // try and get the source
      // if source does not exist
      if (map.isSourceLoaded('business-source-data')) {

        console.debug('loaded data')

        map.getSource('business-source-data').setData(businessPointData)

      }

      map.addLayer({
        id: "business-data",
        source: "business-source-data",
        type: "circle",
        'minzoom': 17,
        "paint": {
          'circle-radius': 5,
          'circle-color': [
            'match',
            ['get', 'amenity'],
            'bank','#C559FF',
            'restaurant','#F04544',
            'bar','#F04544',
            'pharmacy','#519FFF',
            'supermarket_store','#C5FF4E',
            'convenience_store','#519FFF',
            /* other */ '#ccc'
          ]
        }
      })
    },
    addStreetDataToMap(streetData){
      // try and get the source
      // if source does not exist
      if (map.isSourceLoaded('street-source-data')) {
        map.getSource('street-source-data').setData(streetData)
      }

      map.addLayer({
        id: 'street-bins',
        source: 'street-source-data',
        type: 'line',
        paint: {
          "line-color":['get', 'color'],
          "line-width": 3
        }
      })

      this.createMapTooltip('street-bins');
      this.loadingFlag = false;
    },
    updateMap(sliderTime){
      console.debug("updating map at ", sliderTime)

      this.streetData.features.forEach((d)=>{
        d['properties']['color'] = this.colorScale[sliderTime](d.properties[sliderTime])
      });

      map.getSource('street-source-data').setData(this.streetData)
    },
    onMapLoaded(event){
      console.debug('map is loaded')

      map = event.map
      // add 3d building data
      // this.add3dBuildings();

      this.createBusinessDataSource();
      // add blank container to map
      this.createStreetDataSource();
      this.addOpenStreetsData();
      // call data!
      this.$store.dispatch('readStreetData',[]);
      this.$store.dispatch('readBusinessData', []);

    },
    createMapTooltip (mapLayer) {

      popup = new (this).mapbox.Popup({ closeButton: true, closeOnClick: true })

      map.on('click', mapLayer, (e) => {

        e.originalEvent.cancelBubble = true

        if (document.querySelector('.mapboxgl-popup') === null) {

          var prop = e.features[0]['properties']

          const t = "_"+this.getSliderTimeSplit
          // walking pedestrian /hr = value / 0.04
          // queuing pedestrian /hr = value / 0.1

          const multiplier = 10
          const population_queue = (prop["p_queue"+t] / 0.1),
            convienent_store = ((prop['phar'+t]) * multiplier),
            bank = (prop['bank'+t] * multiplier),
            restaurants_bars = ((prop['rest'+t]) * multiplier),
            supermarket = (prop['supe'+t] * multiplier),
            population_total = (prop['p_total'+ t]),
            area_per_person = (Math.round(prop['area_p'+t] * 10) / 10);

            this.$store.dispatch('readStreetAddressFromLatLng', e).then(response => {

              popup.setLngLat([e['lngLat']['lng'], e['lngLat']['lat']])
              .setHTML(`<div class='container' style='pointer-events:none;'><div class='row mt-2'><div class='col'><h3>${response}</h3><br /><h4>${area_per_person} sqft sidewalk area per person</h4></div></div><hr><div class='row mt-2'><div class='col tooltip-column'><h5>Pedestrian Traffic: </h5><h4> ${population_total} /hr</h4></div></div><div class='row mt-2'><div class='col tooltip-column'><h5>Pedestrian Queue Total: </h5><h4>${population_queue} /hr</h4></div></div>
              <div class='tooltip-list'>
                  <div class='tooltip-subitem' ><h6>Restaurant/Bar: </h6><h5> ${restaurants_bars} /hr</h5></div>
                  <div class='tooltip-subitem' ><h6>Supermarket: </h6><h5> ${supermarket} /hr</h5></div>
                  <div class='tooltip-subitem' ><h6>Convenience/Pharmacy: </h6><h5> ${convienent_store} /hr</h5></div>
                  <div class='tooltip-subitem' ><h6>Bank: </h6><h5> ${bank} /hr</h5></li>
              </div></div>`)
              .addTo(map);
              
            }, error => {
              console.error("Got nothing from geocoding server.", error)
            })
        }
      })

      map.on('mousemove', mapLayer, (e) => {

        map.getCanvas().style.cursor = 'pointer'

        featureID = e.features[0].id

        if (e.features.length > 0) {
          if (featureID !== null) {
            map.setFeatureState(
              { source: 'street-source-data', id: featureID },
              { hover: false }
            )
          }

          map.setFeatureState(
            { source: 'street-source-data', id: featureID },
            { hover: true }
          )
        }
      })

      map.on('mouseleave', mapLayer, () => {
        map.getCanvas().style.cursor = ''

        if (featureID !== null) {
          map.setFeatureState(
            { source: 'street-source-data', id: featureID },
            { hover: false }
          )
        }

        featureID = null
      })
    }
  }
}
</script>

<style lang="scss">


/** vue slide up/down transition */
.slide-enter-active {
   transition-duration: 0.4s;
   transition-timing-function: ease-in;
}
.slide-leave-active {
   transition-duration: 0.3s;
   transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
}
.slide-enter-to, .slide-leave {
   max-height: 100vh;
   overflow: hidden;
}
.slide-enter, .slide-leave-to {
   overflow: hidden;
   max-height: 0;
}
/* end of vue transition*/



// MAP OPTIONS
#Map {
  width: 100vw;
  height: 100vh;
  overflow:hidden;
}
.mapboxgl-canvas {
  left: 0;
}
#map-container {
  position: absolute;
  height: 100vh;
  width: 100vw;
  left: 0;
  top: 0;

  overflow: hidden;
}
.mapboxgl-popup-content{
  // frosted glass effect
  -webkit-backdrop-filter: blur(6px);
  backdrop-filter: blur(6px);
  background-color: rgba(18,18,18,0.85);
  width: 375px;
}
.mapboxgl-popup-close-button{
  color: #ffffff;
}
.mapboxgl-ctrl-bottom-left{
  display: none;
}

.tooltip-column{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.tooltip-list{
  color: $off-white;
  flex-direction: column;
  left: 15px;
  position: relative;
  margin-top: 0.5rem;

  .tooltip-subitem{
    display: flex;


    h5{
      margin-left: 0.5rem;
    }
    h6{
      display: list-item;
      list-style-type: disc;
      list-style-position: inside; 
    }
  }
}

// ABOUT
.about-close{
  position: absolute;
  right: 1rem;
  top: 1rem;
}

#about-container{
  position: absolute;
  left: 0;

  background: $black;

  overflow-y: auto;

  top: 0px;
  width: 100%;

  &.mobile{
    height: calc(100vh - 80px);
  }
  &.desktop{
    height: 100vh;
  }
  

  z-index: 5;
}
</style>
